import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import SwiperCore, { Zoom, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import PrimaryButton from 'components/Buttons/PrimaryButton';
import Container from 'components/Container/Container';
import HeroText from 'components/HeroText/HeroText';
import Layout from 'components/Layout';
import OffersCarousel from 'components/OffersCarousel/OffersCarousel';
import Price from 'components/Price/Price';
import Seo from 'components/Seo';
import SizeRadio from 'components/SizeButton/SizeRadio';
import TitleBrand from 'components/TitleBrand/TitleBrand';

import useData from 'data/useData';

import { useCartContext } from 'hooks/useCartContext';

import dataLayerPush from 'utilities/dataLayer';
import fbqEvent from 'utilities/fbq';

import CartStore from 'store/cart';

import 'swiper/swiper.scss';

import 'swiper/components/thumbs/thumbs.scss';
import 'swiper/components/zoom/zoom.scss';

import { linkInitialUnderlined } from 'styles/components/links.module.scss';
import { productHeader } from 'styles/components/typography.module.scss';
import { sticker } from 'styles/components/typography.module.scss';
import * as styles from 'styles/pages/product.module.scss';
import { imageContainer } from 'styles/pages/product.module.scss';

import 'styles/overrides/react-select.scss';
import 'styles/overrides/swiper-product.scss';
import 'styles/pages/product.scss';

SwiperCore.use([Thumbs, Zoom]);

const thumbs = {
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
  slidesPerView: 'auto',
  spaceBetween: 16,
  className: 'swiper-product-thumbs',
};
const settings = {
  spaceBetween: 20,
  slidesPerView: 1,
  zoom: true,
  autoHeight: true,
  className: 'swiper-product-container',
};

const imgStyle = { height: 'auto' };

export default function ProductPage({
  data: { strapiProduct: product, strapiCategory: category },
}) {
  const { data: relatedProducts } = useData(
    `/products/related?category=${category.strapiId}&sku=${product.sku}`
  );
  const [selectedSize, setSelectedSize] = useState('');
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const { cart } = useCartContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const brandLowerCase =
    product.brand.title && product.brand.title.toLowerCase();

  const onSubmit = () => {
    addToCart(selectedSize || '');
  };

  const addToCart = (size = '') => {
    let current = cart.find(
      (item) => item.id === product.id && size === item.product.size
    );
    let quantity = (current && current.quantity ? current.quantity : 0) + 1;
    CartStore.addCartItem({ ...product, size }, quantity);
    setShowSuccess(true);
    try {
      fbqEvent('track', 'AddToCart', {
        value: product.finalPrice / 100,
        currency: 'EUR',
        content_type: 'product',
        content_ids: product.sku,
      });
      dataLayerPush({ ecommerce: null });
      dataLayerPush({
        event: 'addToCart',
        ecommerce: {
          currencyCode: 'EUR',
          add: {
            products: [
              {
                name: product.title,
                id: product.sku,
                price: product.finalPrice / 100,
                category: category.title,
                quantity,
              },
            ],
          },
        },
      });
    } catch (e) {
      // console.log(e);
    }
  };

  useEffect(() => {
    try {
      fbqEvent('track', 'ViewContent', {
        value: product.finalPrice / 100,
        currency: 'EUR',
        content_type: 'product',
        content_ids: product.sku,
      });
      dataLayerPush({ ecommerce: null });
      dataLayerPush({
        ecommerce: {
          detail: {
            products: [
              {
                name: product.title,
                id: product.sku,
                price: product.finalPrice / 100,
                category: category.title,
              },
            ],
          },
        },
      });
    } catch (e) {
      // console.log(e);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let mounted = true;
    if (showSuccess === true) {
      setTimeout(() => {
        mounted && setShowSuccess(false);
      }, 3000);
    }
    return () => {
      mounted = false;
    };
  }, [showSuccess]);

  return (
    <Layout fullWidth={true}>
      <Seo
        title={`${product.title} | ${category.title}${
          category.strapiParent && ' | ' + category.strapiParent.title
        }`}
        description={
          product.description ||
          `Δείτε ${product.title} στην κατηγορία ${category.title} σε μοναδικές τιμές από το ηλεκτρονικό κατάστημα Faidra Concept για γρήγορη και εύκολη αγορά.`
        }
        meta={product.images.map((image) => {
          return { property: 'og:image', content: image.url };
        })}
      />
      <HeroText
        title={product.title}
        description={
          <div className={styles.breadcrumbs}>
            <Link className={`${linkInitialUnderlined} ${styles.link}`} to="/">
              ΑΡΧΙΚΗ
            </Link>
            <span className={styles.dot}>&middot;</span>
            {category.strapiParent && category.strapiParent.slug && (
              <>
                <Link
                  className={`${linkInitialUnderlined} ${styles.link}`}
                  to={`/${category.strapiParent.slug}`}
                >
                  {category.strapiParent.title}
                </Link>
                <span className={styles.dot}>&middot;</span>
              </>
            )}
            <Link
              className={`${linkInitialUnderlined} ${styles.link}`}
              to={`${
                (category.strapiParent &&
                  category.strapiParent.slug &&
                  '/' + category.strapiParent.slug) ||
                ''
              }/${category.slug}`}
            >
              {category.title}
            </Link>
            <span className={styles.dot}>&middot;</span>
            <Link
              className={`${linkInitialUnderlined} ${styles.link} ${styles.active}`}
              to={product.relativePath}
            >
              {product.title}
            </Link>
          </div>
        }
      />
      <Container withPadding isContent>
        <div className={styles.container}>
          {product && product.images && product.images.length > 0 && (
            <div className={styles.imgContainer}>
              {product.sticker && (
                <div className={styles.sticker}>
                  <span className={sticker}>{product.sticker}</span>
                </div>
              )}
              <Swiper
                thumbs={{
                  swiper: thumbsSwiper,
                }}
                {...settings}
              >
                {product.localFile.map((image, i) => (
                  <div key={i} className="swiper-zoom-container">
                    <SwiperSlide key={i}>
                      <GatsbyImage
                        className={imageContainer}
                        imgStyle={imgStyle}
                        image={getImage(image)}
                        alt={`${product.title} ' - ' ${category.title}`}
                      />
                    </SwiperSlide>
                  </div>
                ))}
              </Swiper>
              <Swiper onSwiper={setThumbsSwiper} {...thumbs}>
                {product.localFile.map((image, i) => (
                  <SwiperSlide key={i}>
                    <GatsbyImage
                      className={imageContainer}
                      image={getImage(image)}
                      alt={`${product.title} ' - ' ${category.title}`}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
          <div className={styles.details}>
            <div className={styles.detailsBrandLogoContainer}>
              <div>
                <h1 className={`${productHeader}`}>{product.title}</h1>
                <h3 className={styles.skuContainer}>
                  <span className={styles.label}>Κωδικός προϊόντος:</span>{' '}
                  <span className={styles.spec}>{product.sku}</span>
                </h3>
              </div>
              {brandLowerCase.includes('studio 83') && (
                <StaticImage
                  quality={100}
                  layout="fixed"
                  width={104}
                  className={styles.brandImage}
                  src="../images/studio83.png"
                  alt="Studio 83 logo - Clothing for the wanderers"
                />
              )}
              {brandLowerCase.includes('samouka') && (
                <StaticImage
                  quality={100}
                  layout="fixed"
                  width={104}
                  className={styles.brandImage}
                  src="../images/anna.png"
                  alt="Anna Samouka logo"
                />
              )}
              {brandLowerCase.includes('ofilia') && (
                <StaticImage
                  quality={100}
                  layout="fixed"
                  width={104}
                  className={styles.brandImage}
                  src="../images/ofilias.png"
                  alt="Ofilias logo"
                />
              )}
              {brandLowerCase.includes('mairis') && (
                <StaticImage
                  quality={100}
                  layout="fixed"
                  width={104}
                  className={styles.brandImage}
                  src="../images/mairis.png"
                  alt="MAIRIS logo"
                />
              )}
              {brandLowerCase.includes('emci') && (
                <StaticImage
                  quality={100}
                  layout="fixed"
                  width={104}
                  className={styles.brandImage}
                  src="../images/emci.png"
                  alt="Emci logo"
                />
              )}
            </div>
            <div className={styles.priceContainer}>
              <Price
                beginPrice={product.beginPrice}
                finalPrice={product.finalPrice}
                fpClass={styles.fpClass}
                bpClass={styles.bpClass}
              />
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={styles.sizeContainer}>
                {product.variants && product.variants.length > 0 && (
                  <>
                    <h3 className={styles.label}>Μέγεθος</h3>
                    <div className={styles.sizeVariants}>
                      {product.variants.map(({ size }) => (
                        <SizeRadio
                          register={register}
                          key={size}
                          name="size"
                          size={size}
                          handleClick={() => {
                            setSelectedSize(size);
                          }}
                          isSelected={selectedSize === size}
                          required
                        />
                      ))}
                    </div>
                  </>
                )}
              </div>
              <PrimaryButton buttonClass={styles.addToCartBtn} type="submit">
                ΠΡΟΣΘΗΚΗ ΣΤΟ ΚΑΛΑΘΙ
              </PrimaryButton>
              {errors.size && (
                <p className={styles.errorMsg}>Πρέπει να επιλέξεις μέγεθος.</p>
              )}
              <p
                className={`${styles.successMsg}${
                  showSuccess ? ' ' + styles.active : ''
                }`}
              >
                Το προϊόν προστέθηκε επιτυχώς στο καλάθι!
              </p>
            </form>
            {product.description && (
              <div className={styles.descriptionSection}>
                <p className={styles.descriptionTitle}>ΠΕΡΙΓΡΑΦΗ</p>
                <p className={styles.description}>{product.description}</p>
              </div>
            )}
          </div>
        </div>
      </Container>
      {relatedProducts && relatedProducts.length > 0 && (
        <OffersCarousel
          title={<TitleBrand text="Similar products" />}
          products={relatedProducts}
          containerClass={styles.relatedContainer}
        />
      )}
    </Layout>
  );
}

export const query = graphql`
  query SingleProduct($id: String!, $categoryId: String!) {
    strapiProduct(strapiId: { eq: $id }) {
      id: strapiId
      beginPrice
      description
      finalPrice
      color
      enabled
      offerType
      relativePath
      sku
      slug
      sticker
      title
      brand {
        title
      }
      variants {
        quantity
        size
      }
      mainCategory {
        slug
        title
      }
      subCategory {
        slug
        title
      }
      images {
        hash
        url
      }
      localFile {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, quality: 100)
        }
      }
    }
    strapiCategory(strapiId: { eq: $categoryId }) {
      strapiId
      title
      strapiParent {
        title
        slug
      }
      slug
      localFile {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  }
`;
