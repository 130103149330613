// extracted by mini-css-extract-plugin
export var breadcrumbs = "product-module--breadcrumbs--3_x7p";
export var link = "product-module--link--3DB7w";
export var active = "product-module--active--3fTfo";
export var dot = "product-module--dot--1J6eT";
export var container = "product-module--container--cXF-d";
export var imgContainer = "product-module--imgContainer--1ZVsJ";
export var details = "product-module--details--1iTVQ";
export var imageContainer = "product-module--imageContainer--ExoIK";
export var detailsBrandLogoContainer = "product-module--detailsBrandLogoContainer--NADs6";
export var brandImage = "product-module--brandImage--W_Wnq";
export var label = "product-module--label--2Bm3L";
export var skuContainer = "product-module--skuContainer--172CD";
export var available = "product-module--available--2rMO0";
export var spec = "product-module--spec--33a2n";
export var specsSection = "product-module--specsSection--1Dmxu";
export var specsContainer = "product-module--specsContainer--3jOv2";
export var priceContainer = "product-module--priceContainer--2C4HI";
export var fpClass = "product-module--fpClass--ebnvI";
export var bpClass = "product-module--bpClass--RprI2";
export var sticker = "product-module--sticker--s1HZj";
export var descriptionSection = "product-module--descriptionSection--72jjn";
export var descriptionTitle = "product-module--descriptionTitle--cKBUJ";
export var description = "product-module--description--19kuS";
export var addToCartContainer = "product-module--add-to-cart-container--2hNFE";
export var sizeContainer = "product-module--sizeContainer--2ml6E";
export var sizeVariants = "product-module--sizeVariants--3oBLc";
export var quantityContainer = "product-module--quantity-container--1VL2N";
export var select = "product-module--select--81BQT";
export var addToCartBtn = "product-module--add-to-cart-btn--3s4uJ";
export var successMsg = "product-module--success-msg--PI_Rz";
export var errorMsg = "product-module--error-msg--3ADhp";
export var relatedContainer = "product-module--relatedContainer--30lqL";