import React from 'react';

import * as styles from './styles.module.scss';

const SizeRadio = ({
  size,
  name,
  handleClick,
  isSelected,
  isDisabled = false,
  register,
  required,
}) => {
  return (
    <label
      className={`${styles.size} ${isSelected && styles.selected} ${
        isDisabled && styles.disabled
      }`}
    >
      {size}
      <input
        key={size}
        type="radio"
        {...register(name, { required })}
        aria-label={name}
        onChange={handleClick}
        checked={isSelected}
        disabled={isDisabled}
        className={styles.radio}
      />
    </label>
  );
};

export default SizeRadio;
